.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Align items in column layout */
  height: 100vh; /* Set the height of the container to full viewport height */
}

.App-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.App-logo {
  width: 150px; /* Adjust the width of the logo as needed */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px; /* Add space below the logo */
}

.Signin-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Add space between icon and text */
  padding: 10px 20px; /* Adjust button padding */
  font-size: 16px; /* Adjust font size */
  color: #007bff; /* Blue text color */
  background-color: transparent; /* Transparent background */
  border: 1px solid #007bff; /* Blue border */
  border-radius: 5px; /* Add border-radius for rounded corners */
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease; /* Smooth transitions */
}

.Signin-button:hover {
  background-color: #007bff; /* Blue background on hover */
  color: #fff; /* White text color on hover */
  border-color: #007bff; /* Blue border color on hover */
}

.Signin-icon {
  width: 20px; /* Adjust icon size */
  height: 20px;
  stroke-width: 2; /* Icon stroke width */
  vertical-align: middle; /* Align icon vertically */
}

.footer {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 20px;
}

.text {
  text-align: center; /* Center align the text */
  color: aliceblue;
}
