.outer-container {
    display: flex;
    flex-direction: row-reverse;
    position: fixed;
    right: 20px;
    top: 20px; 
    z-index: 1000;
}

.toggle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px; /* Set the width of the toggle button */
    height: 40px; /* Set the height of the toggle button */
    background-color: #f3f3f3;
    border-radius: 50%; /* Make it circular */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.toggle-container:hover {
    background-color: #ccc; /* Change background color on hover */
}

.toggle-icon {
    width: 24px; /* Set the width of the toggle button icon */
    height: 24px; /* Set the height of the toggle button icon */
}

.parent-container {
    display: flex;
    flex-direction: row-reverse;
    position: fixed;
    right: 20px; /* Adjust as needed */
    bottom: 20px; /* Adjust as needed */
    z-index: 1000; /* Ensure it's above other content */
}

.toolbar-container {
    display: flex;
    flex-direction: column;
    background-color: #f3f3f3;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.toolbar {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.toolbar-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background-color: #f3f3f3;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 5px 0;
}

.toolbar-button:hover {
    background-color: #777;
}

.icon {
    width: 30px;
    height: 30px;
}

.svgs {
    height: 30px;
    width: 30px;
    margin: 0px 0px 10px 0px;
}

.selector{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin: 10px 0px 0px 0px; */
}

.selected {
    background-color: #C2C2C2;
    border-radius: 20%;
}

.touch-icon {
    height: 30px;
    width: 30px;
}

.toggle-icon {
    height: 30px;
    width: 30px;
} 
