.Profile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5; /* Light background color */
}

.Profile-card {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.Profile-card h2 {
  margin-bottom: 20px;
}

.Profile-card p {
  margin-bottom: 10px;
}

.Profile-buttons {
  display: flex;
  justify-content: space-between;
}

.Profile-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.Profile-button:hover {
  background-color: #0056b3;
}