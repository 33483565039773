Reset some default styles
body, h1, h2, p, ul, ol, li {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background-color: #f8f8f8;
    color: #333;
    line-height: 1.6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
}

header {
    text-align: center;
    padding: 80px 0;
}

header h1 {
    font-size: 36px;
    margin-bottom: 10px;
}

header p {
    font-size: 18px;
    margin-bottom: 40px;
}

.section {
    padding: 40px;
    margin-bottom: 40px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section h2 {
    font-size: 28px;
    margin-bottom: 20px;
}

.section p {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.features ul, .why-choose ol {
    margin-left: 20px;
}

.features ul li, .why-choose ol li {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
}

.download-links a {
    display: inline-block;
    margin-right: 10px;
    padding: 12px 30px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 30px;
    font-weight: bold;
    transition: background-color 0.3s;
}

.download-links a:hover {
    background-color: #0056b3;
}

.stay-connected a {
    text-decoration: underline;
    color: #007bff;
}

footer {
    text-align: center;
    color: #777;
    padding: 20px;
}

.footer-links a {
    color: #777;
    text-decoration: none;
    margin: 0 10px;
    transition: color 0.3s;
}

