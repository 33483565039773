.Toast {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
    color: #fff; /* White text color */
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1000; /* Ensure the toast is above other content */
    opacity: 0; /* Initially hide the toast */
    transition: opacity 0.3s ease; /* Fade-in animation */
  }
  
  .Toast.show {
    opacity: 1; /* Fade in the toast when shown */
  }
  
  .Toast-message {
    font-size: 16px;
  }