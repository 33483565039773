.listcontainer {
    display: flex;
    margin: 20px;
    justify-content: center;
    flex-direction: row;
    background-color: #f3f3f3;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.text {
    color: #333;
    font-family: Arial, sans-serif;
    font-size: 13px;
    margin-bottom: 5px;
}


.button {
    border: none;
    font-size: 10px;
    padding: 5px 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: #c0c0c0;
}

.button:hover {
    background-color: #d32f2f;
}