body {
  margin: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;    
     -moz-user-select: none;
      -ms-user-select: none; 
          user-select: none; 
}

.wrapper {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.frame {
  box-shadow: 5px 5px 5px rgb(161, 161, 161);
  align-items: center;
  justify-content: center;
}

.drawable-canvas {
  position: absolute;
  z-index: 99; top: 0; left: 0;
}

.finalizewrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}